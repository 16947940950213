.cover {
    width: 100%;
    height: 200px;
}

.center {
    display: flex;
    justify-content: center;
}

.circle {
    border: 4px solid white;
    height: 175px;
    border-radius:50%;
    width: 175px;
    margin-top: -125px;
}

.test {
    margin-top: -25px;
}